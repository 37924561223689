import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Image from '../components/uielements/Image'
import { Link } from 'gatsby'
import MainTitle from '../components/uielements/MainTitle'
import Button from '../components/uielements/Button'
import ContentRectangle from '../components/uielements/ContentRectangle'
import Spacer from '../components/uielements/Spacer'
import Flex from '../components/uielements/Flex'
import FlexWrapper from '../components/uielements/flexWrapper'
import { screenSizes } from '../lib/constants'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'
import RichContent from '../components/uielements/RichContent/richContent'

const MainBox = styled.div`
    display: flex;
    margin: 10px;
    box-shadow: 0 0 10px #ccc;

    img {
        max-width: 100%;
        height: auto;
    }

    @media (max-width: ${screenSizes.md}px) {
        flex-direction: column;
    }
`

const MainBoxImage = styled.div`
    width: 40%;
    flex-basis: 40%;

    @media (max-width: ${screenSizes.md}px) {
        width: 100%;
        flex-basis: 100%;
    }
`

const InfoBox = styled.div`
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 60%;
`

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        color: ${({ theme }) => theme.purple};
        font-weight: bold;
        margin-top: 10px;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
`

const TeamPage = ({ data: { team } }) => {
    const { t, i18n } = useTranslation()

    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('who-we-are')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('who-we-are')} />
                <Spacer top="6" left="1.4">
                    <MainTitle>{t('who-we-are')}</MainTitle>
                    <Spacer top="4" />
                </Spacer>

                {team.nodes.map(node =>
                    node.order === 0
                        ? i18n.language === node.node_locale && (
                              <MainBox key={node.title}>
                                  <MainBoxImage>
                                      <Link
                                          title={node.title}
                                          to={`/team/${node.slug}`}
                                      >
                                          <Image obj={node.featuredImage} />
                                      </Link>
                                  </MainBoxImage>
                                  <InfoBox>
                                      <TitleWrapper>
                                          <MainTitle>{node.title}</MainTitle>
                                          <h3>{node.position}</h3>
                                      </TitleWrapper>
                                      <p>
                                          <RichContent
                                              body={node?.excerpt?.raw}
                                          />
                                      </p>
                                      <ButtonWrapper>
                                          <Button link={`/team/${node.slug}`}>
                                              {t('read-more')}
                                          </Button>
                                      </ButtonWrapper>
                                  </InfoBox>
                              </MainBox>
                          )
                        : ''
                )}
                <Spacer top="6" left="1">
                    <MainTitle>{t('team')}</MainTitle>
                </Spacer>
                <Spacer top="6" />
                <FlexWrapper xl={{ margin: '0 -10px 0 0' }}>
                    {team.nodes.map(node =>
                        node.order === 0
                            ? ''
                            : i18n.language === node.node_locale && (
                                  <Flex
                                      flexBasis="33%"
                                      key={node.id}
                                      sm={{ flexBasis: '100%' }}
                                      md={{ flexBasis: '50%' }}
                                      lg={{ flexBasis: '33%' }}
                                  >
                                      <Spacer
                                          left="1"
                                          right="1"
                                          bottom="2"
                                          sm={{ right: '0', bottom: '2' }}
                                      >
                                          <Link
                                              title={node.title}
                                              to={'/team/' + node.slug}
                                          >
                                              <ContentRectangle
                                                height={'600px'}
                                                  imgObj={node.featuredImage}
                                              >
                                                  {node.title}
                                              </ContentRectangle>
                                          </Link>
                                      </Spacer>
                                  </Flex>
                              )
                    )}
                </FlexWrapper>
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query teamQuery {
        team: allContentfulTeam(sort: { fields: order, order: ASC }) {
            nodes {
                title
                slug
                order
                position
                node_locale
                body {
                    raw
                }
                excerpt {
                    raw
                }
                featuredImage {
                    url
                    title
                    gatsbyImageData(
                        quality: 75
                        jpegProgressive: true
                        sizes: "1920, 960, 480"
                        cropFocus: CENTER
                    )
                }
            }
        }
    }
`

export default TeamPage
